<script lang="ts" setup>
const localePath = useLocalePath();
const navMenuOpen = ref(false);
const { afterEach } = useRouter();

afterEach(() => { navMenuOpen.value = false; });
</script>

<template>
  <nav class="navbar has-background-light" role="navigation" aria-label="main navigation">
    <div class="container">
      <div class="navbar-start">
        <div class="navbar-brand is-align-items-center py-3 px-3">
          <NuxtLink class="app-navbar-logo" :to="localePath('/')">
            <img v-if="$i18n.locale === 'de'" src="~/assets/img/42he-logo-de.svg" alt="42he" width="120" height="60">
            <img v-else src="~/assets/img/42he-logo-en.svg" alt="42he" width="120" height="60">
          </NuxtLink>
          <a
            role="button"
            class="navbar-burger"
            aria-label="menu"
            aria-expanded="false"
            @click="navMenuOpen = !navMenuOpen"
          >
            <span aria-hidden="true" />
            <span aria-hidden="true" />
            <span aria-hidden="true" />
          </a>
        </div>
      </div>
      <div
        class="navbar-menu"
        :class="{ 'navbar-menu-toggle': navMenuOpen }"
      >
        <div class="navbar-end">
          <NuxtLink v-if="$i18n.locale === 'de'" :to="localePath('/careers')" class="navbar-item">
            {{ $t('app-navbar.jobs') }}
          </NuxtLink>
          <NuxtLink v-if="$i18n.locale === 'de'" :to="localePath('/blog')" class="navbar-item">
            {{ $t('app-navbar.blog') }}
          </NuxtLink>
          <NuxtLink v-if="$i18n.locale === 'de'" :to="localePath('/kontakt')" class="navbar-item">
            {{ $t('app-navbar.contact') }}
          </NuxtLink>
          <div class="navbar-item">
            <LanguageSwitcher />
          </div>
        </div>
      </div>
    </div>
  </nav>
</template>

<style lang="scss" scoped>
.app-navbar-logo {
  width: 120px;
  height: auto;
}

@media screen and (max-width: 950px) {
  .app-navbar-logo {
    width: 80px;
  }
}

@media screen and (max-width: 1024px) {
  .navbar-item {
    padding: 20px;
    text-align: center;
  }

  .navbar-menu {
    position: absolute;
    display: none;
    width: 100vw;
    background-color: #f2f5ff;
  }

  .navbar-menu-toggle {
    display: block;
  }
}
</style>
