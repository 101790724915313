<script lang="ts" setup>
const localePath = useLocalePath();
</script>

<template>
  <footer class="footer has-background-light">
    <div class="app-footer container">
      <div>
        <NuxtLink class="app-footer-logo" :to="localePath('/')">
          <img v-if="$i18n.locale === 'de'" src="~/assets/img/42he-logo-de.svg" alt="42he" width="120" height="60">
          <img v-else src="~/assets/img/42he-logo-en.svg" alt="42he" width="120" height="60">
        </NuxtLink>
      </div>
      <div class="app-footer-item">
        <NuxtLink v-if="$i18n.locale === 'de'" :to="localePath('/careers')" class="has-text-dark">
          {{ $t('app-footer.jobs') }}
        </NuxtLink>
        <NuxtLink v-if="$i18n.locale === 'de'" :to="localePath('/blog')" class="has-text-dark">
          {{ $t('app-footer.blogs') }}
        </NuxtLink>
        <NuxtLink target="_blank" to="https://centralstationcrm.de/" class="has-text-dark">
          {{ $t('app-footer.crm-link') }}
        </NuxtLink>
        <NuxtLink target="_blank" to="https://centralplanner.de/" class="has-text-dark">
          {{ $t('app-footer.cp-link') }}
        </NuxtLink>
        <NuxtLink v-if="$i18n.locale === 'de'" target="_blank" to="https://partner.42he.com/" class="has-text-dark">
          {{ $t('app-footer.partnerprogramm-link') }}
        </NuxtLink>
        <NuxtLink v-if="$i18n.locale === 'de'" :to="localePath('/kontakt')" class="has-text-dark">
          {{ $t('app-footer.contact-link') }}
        </NuxtLink>
        <NuxtLink v-if="$i18n.locale === 'de'" :to="localePath('/datenschutz')" class="has-text-dark">
          {{ $t('app-footer.datenschutz') }}
        </NuxtLink>
        <NuxtLink v-if="$i18n.locale === 'de'" :to="localePath('/impressum')" class="has-text-dark">
          {{ $t('app-footer.imprint') }}
        </NuxtLink>
        <NuxtLink v-if="$i18n.locale === 'en'" :to="localePath('/impress')" class="has-text-dark">
          {{ $t('app-footer.imprint') }}
        </NuxtLink>
      </div>
    </div>
  </footer>
</template>

<style lang="scss" scoped>
.app-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.app-navbar-logo {
  width: 100px;
  height: auto;
}

.app-footer-item {
  display: flex;
  gap: 20px;
}

@media screen and (max-width: 720px) {
  .app-navbar-logo {
    width: 70px;
  }
}

@media screen and (max-width: 950px) {
  .app-footer {
    flex-direction: column;
    gap: 20px;
  }

  .app-footer-item {
    flex-wrap: wrap;
    justify-content: center;
    padding: 0 50px;
    text-align: center;
  }
}
</style>
